<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div class="col-4">
        Filter
        <div class="row">
          <div class="form-group">
            <label for="type">Type de produit</label>
            <select
              class="form-control"
              v-model="type"
              v-select="{placeholder: 'Selectionnez le type de produit'}"
            >
              <option value="" />
              <option
                v-for="(t, index) in types"
                :value="t.uid"
                :key="index"
              >
                {{ t.libelle }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col-7">
            Search
          </div>
          <div class="col-5">
            Order
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navbar from '../../components/navbar.vue'
import Select from '../../directive/select'
export default {
    components: { Navbar },
    directives: { Select },
    data(){
        return {
            navbarItems: [
              {
                libelle: 'Market Place'
              }
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Market Place',
            pageDescription: 'Espace de vente des produits sur CASEAC',
            type: null, 
            categorie: null,
            tags: []
        }
    },
    computed: {
        ...mapGetters({
            types: 'market/typeProducts',
            categories: 'market/categories',
            filtres: 'market/filtres'
        })
    }

}
</script>

<style>

</style>